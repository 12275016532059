.noti-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.noti-modal-container {
    position: relative;
    background: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    border: none !important;
    padding: 0 0 24px 0;
    max-width: 600px;
    text-align: center;
    z-index: 1000;
}

.noti-modal-content-wrapper {
    padding: 2rem 1rem 1rem 1rem;
    font-size: 20px;
}

.noti-btn-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.noti-modal-button {
    min-width: 100px;
}

@media (max-width: 480px) {
    .noti-modal-container {
        position: absolute !important;
        width: 336px !important;
    }
}