.stations-page-wrapper {
  .ols-wrapper-list {
    max-height: 650px;
  }

  .station-page {
    max-height: 72vh;
    overflow-y: auto;
  }

  .export-wrapper {
    margin: 1rem 1rem 0 0;
  }
}