.reception-order-item {

  .ol-item-wrapper {
    display: flex;
    text-align: center;
    padding: 1px;
    flex: 1;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
    background-color: rgba(244, 244, 244, 0.3);
    color: rgba(0, 0, 0, 1);

    &:hover {
      color: rgba(0, 0, 0, 1);
      font-weight: bold;
      background-color: #e5e5e5;
      background-color: rgba(22, 119, 161, 0.05);
    }

    &.attention {
      background-color: rgba(250, 250, 150, 0.6);

      &:hover {
        background-color: rgba(250, 250, 150, 1);
      }
    }

    .ol-edit-btn {
      font-size: 16px;
      border-radius: 10px;
      padding: 3px 16px;
      font-weight: 400;
      letter-spacing: 1px;
      background: none;
      border: solid rgba(0, 0, 0, 0.5) 1px;
      color: rgba(0, 0, 0, 0.5);

      &:hover {
        border: solid rgba(0, 0, 0, 1) 1px;
        color: rgba(0, 0, 0, 1);
      }

      &:hover:hover {
        border: solid #29a7df 1px;
        background-color: #29a7df;
        color: #fff;
      }

      &:hover:active {
        border: solid #29a7df 1px;
        background-color: #29a7df;
        color: #fff;
      }
    }
  }

  .is-ble {
    color: rgb(51, 172, 67);
    font-size: 21px;
  }

  .item-wrapper {
    .btn-redirect {
      font-size: 16px;
      border-radius: 10px;
      padding: 4px 24px;
      font-weight: 400;
      letter-spacing: 1px;
      background: none;
      border: 1px solid transparent;
      color: rgba(0, 0, 0, 0.5);
      cursor: pointer;
      margin: 4px 0;

      &:hover {
        border: solid #29a7df 1px;
        background-color: #29a7df;
        color: #fff;
      }
    }

    >div,
    >div>div {
      max-width: 200px;
      word-wrap: break-word;
    }
  }
}