.homePage {
  padding: 12px 20px 0 0;
  height: calc(100vh - 50px);
  overflow: auto;

  h3 {
    padding: 0px 24px 6px 0;
  }

  .delivery-btns-holder {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    max-width: 88vw;
  }

  .welcome-title {
    margin-bottom: 20px;
  }

  .links-holder {
    display: flex;
    flex-wrap: wrap;
    max-width: 88vw;
    gap: 1rem;
  }

  .link-btn {
    text-align: center;
    color: #ffffff;
    font-family: Heebo;
    background: #0d7fb1;
    box-shadow: -4px 13px 31px rgb(26 95 125 / 24%);
    border-radius: 23px;
    cursor: pointer;
    font-size: 21px;
    min-width: 214px;
    padding: 40px 0px;

    &:hover {
      background-color: #29a7df;
      color: #fff;
    }
  }

  .link-btn.exelot {
    background: #00529a;
    padding: 20px 0px;
  }

  .block-text {
    max-width: 180px;
    text-align: center;
    padding: 0px 15px;
  }

  .separator {
    border-top: 1px solid grey;
    margin: 20px 0 20px 0;
  }

  .wh-operator-wrapper {
    display: flex;
    margin-top: 2rem;
  }
}

@media (max-width: 600px) {
  .homePage {
    width: 94vw;
    margin: 0;
    overflow: hidden;
    overflow-y: scroll;
    padding: 1rem 0 0 0;

    .welcome-title {
      text-align: center;
    }

    .links-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .links-holder {
      flex-direction: column;
      align-items: center;
    }

    .link-btn {
      min-width: 140px;
      padding: 24px 0px;
    }

    .delivery-btns-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .delivery-btns-holder {
      flex-direction: column;
      align-items: center;
    }

    .wh-operator-wrapper {
      justify-content: center;
    }

  }
}