.WHOperator {
  margin-bottom: 2rem;

  h3 {
    padding: 0px 24px 6px 0;
  }

  .laundry-list {
    display: flex;
    flex-wrap: wrap;
  }
}

.supplier_holder {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  max-width: 88vw;
}

@media (max-width: 650px) {
  .laundry-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .supplier_holder {
      display: flex;
      flex-direction: column;
    }
  }


}