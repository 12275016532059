.toast {
    position: fixed;
    padding: 10px 20px;
    background-color: #4ECC11;
    color: white;
    border-radius: 5px;
    opacity: 0;
    z-index: 1000;
}

@keyframes slideInOutLeft {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }

    10% {
        opacity: 1;
        transform: translateX(0);
    }

    90% {
        opacity: 1;
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        transform: translateX(-100%);
    }
}

@keyframes slideInOutRight {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }

    10% {
        opacity: 1;
        transform: translateX(0);
    }

    90% {
        opacity: 1;
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        transform: translateX(100%);
    }
}

.bottom-right {
    bottom: 2rem;
    right: 10%;
    animation: slideInOutRight 3s ease-in-out forwards;
}

.bottom-left {
    bottom: 2rem;
    left: 12rem;
    animation: slideInOutLeft 3s ease-in-out forwards;
}

.top-right {
    top: 2rem;
    right: 6rem;
    animation: slideInOutRight 3s ease-in-out forwards;
}



.top-left {
    top: 2rem;
    left: 6rem;
    animation: slideInOutLeft 3s ease-in-out forwards;
}