.not-alive-stations-wrapper {
  display: inline-block;
  padding: 14px;
  width: 100%;
  max-width: 630px;
  border: solid #d74b4b 2px;
  border-radius: 12px;
  box-shadow: -4px 13px 31px rgba(26, 95, 125, 0.24);
  background-color: #fff;
  margin: 0 0 5px 24px;

  h3 {
    color: #d74b4b;
    font-size: 18px;
    display: flex;
    align-items: center;
  }

  .down-count {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 22px;
    height: 22px;
    font-size: 15px;
    background-color: #d74b4b;
    color: white;
    border-radius: 20px;
    margin-left: 6px;
    padding: 6px;
  }

  .not-alive-stations-list {
    display: flex;
    direction: ltr;
    flex-direction: column;
    gap: 8px;
    direction: ltr;
    height: 175px;
    overflow: auto;
    padding-top: 12px;

    .station-item {
      display: flex;
      direction: rtl;
      flex-wrap: wrap;
      align-items: center;
      gap: 8px;
      padding: 6px;
    }

    .station-item .column {
      display: flex;
      padding: 3px 6px;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }

    .number {
      font-weight: bold;
      width: 50px;
    }

    .name {
      width: 200px;
      max-width: 200px;
    }

    .before-time {
      font-weight: bold;
      color: #d74b4b;
      font-size: 16px;
      width: 200px;
      max-width: 200px;
    }
  }

  .station-item:hover {
    font-weight: bold;
    background-color: rgba(22, 119, 161, 0.05);
  }

  .station-item.title,
  .station-item.title:hover {
    background-color: #e5e5e5;

    .number,
    .name,
    .before-time {
      font-weight: normal;
      color: #333333;
    }
  }

  .not-alive-stations-list::-webkit-scrollbar-track {
    background: rgba(194, 194, 194, 0.23);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    background-clip: padding-box;
  }

  .not-alive-stations-list::-webkit-scrollbar-thumb {
    background: #2faadf;
    border-radius: 11px;
  }

  .not-alive-stations-list::-webkit-scrollbar {
    width: 13px;
  }
}

@media (max-width: 768px) {
  .not-alive-stations-wrapper {
    width: 90%;
    margin: 0 auto;

    .not-alive-stations-list {
      padding-top: 12px;
    }

    .station-item {
      display: block;
      padding: 12px;
      background-color: #f9f9f9;
      border-radius: 12px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    }

    .station-item .column {
      width: 100%;
      margin-bottom: 6px;
    }

    .number,
    .name,
    .before-time {
      width: 100%;
      max-width: none;
    }

    /* Show column names only on small screens */
    .station-item .number:before {
      display: contents;
      content: "מספר: ";
    }

    .station-item .name:before {
      display: contents;
      content: "שם עמדה: ";
    }

    .station-item .before-time:before {
      display: contents;
      content: "עדכון אחרון לפני: ";
    }
  }


}

@media (max-width: 480px) {
  .not-alive-stations-wrapper {
    padding: 10px;
    margin: 0 8px 12px 0;

    .down-count {
      font-size: 13px;
      height: 18px;
      min-width: 18px;
      padding: 4px;
    }

    .station-item {
      font-size: 14px;
      padding: 10px;
      background-color: #f1f1f1;
    }

    .station-item .column {
      width: 100%;
      margin-bottom: 4px;
    }


    .station-item .number:before {
      display: contents;
      content: "מספר: ";
    }

    .station-item .name:before {
      display: contents;
      content: "שם עמדה: ";
    }

    .station-item .before-time:before {
      display: contents;
      content: "עדכון אחרון לפני: ";
    }
  }


}