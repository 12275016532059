.drawer-list-wrapper {
    margin: 0rem 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;

}

@media (max-width: 600px) {
    .drawer-list-wrapper {
        margin: 3rem 0;
    }
   }