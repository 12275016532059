.record-table {
  color: #000;
  height: 306px;
  overflow-y: auto;
  font-size: 16px;
  margin-top: 20px;

  .record-row {
    display: block;
    border-top: dotted 2px #ddd9d9;
  }

  .record-row:nth-child(even) {
    background-color: #f2f2f2;
  }

  .record-col {
    display: inline-block;
    padding: 0 0 0 8px;

    width: 148px;
    vertical-align: middle;
    border-left: dotted 2px #ddd;
  }

  .record-col.location {
    width: 140px;
  }

  .record-col.source-app {
    width: 80px;
  }

  .record-col.icon {
    width: 22px;
  }

  .record-col.note {
    width: 90px;
    padding: 5px 20px;
  }

  .record-col.order-total-price {
    width: 40px;
    padding: 5px 20px;
  }

  .record-col.is-payed {
    width: 25px;
    padding: 3px 20px;
  }

  .record-col.station-change-state {
    width: 25px;
    padding: 3px 20px;
  }

  .fa-check-circle {
    color: rgb(51, 172, 67);
    font-size: 21px;
  }
}