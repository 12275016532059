$primary-color: #29a7df;
$secondary-color: #fda73a;
$hover-color: #3bafe1;
$disabled-color: rgba(0, 0, 0, 0.5);

.blbtn-wrapper {
  cursor: pointer;
  border-radius: 5px;
  background-color: $primary-color;
  text-align: center;
  color: #ffffff;
  font-family: Heebo;
  font-size: 39px;
  font-weight: 400;
  user-select: none;
  margin: 5px;
  border: none;

  &:hover {
    background-color: $hover-color;
  }

  &.disabled {
    cursor: not-allowed;
    background-color: $disabled-color;

    &:hover {
      border: none;
      background-color: $disabled-color;
      color: $disabled-color;
    }
  }
}