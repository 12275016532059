.StationSnapshotsScreen {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;

    h1 {
        font-size: 24px;
        margin-bottom: 20px;
    }

    .error-message {
        color: red;
        font-size: 18px;
        padding: 1rem 0 0 0;
    }

    .search-container {
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: center;

        .station-selector {
            width: 300px;
        }

        .date-time-container {
            display: flex;
            gap: 8px;
            align-items: center;

            .date-picker {
                display: flex;
                align-items: center;
                padding: 8px 8px;
                border-radius: 4px;
                border: 1px solid #ccc;
                width: 6rem;
            }

            .time-picker {
                padding: 8px 12px;
                border-radius: 4px;
                border: 1px solid #ccc;
                width: 4rem;
            }

            .react-datepicker__triangle {
                transform: translateX(0px) !important;
                left: 50% !important;
                margin-left: -8px !important;
            }

        }

        .btn-search-snapshots {
            font-size: 16px;
            padding: 8px 16px;
        }
    }

    .snapshots-grid {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: center;
        margin-top: 20px;

        .photo-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            background: #f9f9f9;
            border-radius: 12px;
            padding: 12px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            transition: transform 0.2s;

            .station-photo {
                width: 100%;
                height: 180px;
                object-fit: cover;
                border-radius: 8px;
                cursor: zoom-in;
            }

            .photo-details {
                width: 100%;
                text-align: center;
                margin-top: 8px;
                font-size: 14px;
            }

            .download-button {
                margin-top: 8px;
                padding: 6px 12px;
                font-size: 14px;
            }
        }

        .photo-card:hover {
            transform: scale(1.03);
        }

        .modal {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            border-radius: 0px;
            background: rgba(0, 0, 0, 0.8);
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1000;
            cursor: pointer;

            .modal-content {
                max-width: 90%;
                max-height: 90%;
                background: white;
                padding: 10px;
                border-radius: 8px;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            }

            .modal-content img {
                width: 100%;
                height: auto;
                max-height: 90vh;
                object-fit: contain;
            }

            .rotate-button {
                position: absolute;
                top: 5%;
                right: 2%;
                background-color: #29a7df;
                color: white;
                border: none;
                padding: 16px 18px;
                border-radius: 5px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                transition: transform 0.2s ease-in-out, background-color 0.3s ease-in-out;

                &:active {
                    transform: scale(0.9);
                }
            }

        }
    }
}