.stations-voltage-wrapper {
  display: inline-block;
  padding: 10px 14px 14px 14px;
  width: 630px;
  border: solid #d74b4b 2px;
  border-radius: 12px;
  box-shadow: -4px 13px 31px rgb(26 95 125 / 24%);
  background-color: #fff;
  margin: 0 0 5px 24px;

  h3 {
    color: #d74b4b;
  }

  .select-wrapper {
    padding: 0 30px 0 0;
    display: inline-block;
    width: 400px;
    color: #333333;
    font-size: 16px;
  }

  .down-count {
    display: inline-block;
    min-width: 22px;
    height: 22px;
    font-size: 15px;
    background-color: #d74b4b;
    color: white;
    border-radius: 20px;
    margin: 0 0 0 6px;
    padding: 6px;
    text-align: center;
  }

  .stations-voltage-list {
    display: flex;
    direction: ltr;
    flex-direction: column;
    gap: 8px;
    direction: ltr;
    height: 175px;
    overflow: auto;
    padding-top: 12px;

    .station-item {
      display: flex;
      direction: rtl;
      flex-wrap: wrap;
      align-items: center;
      gap: 8px;
      padding: 6px;

      .station-item .column {
        display: flex;
        padding: 3px 6px;
        word-wrap: break-word;
        overflow-wrap: break-word;
      }

      .number {
        font-weight: bold;
      }

      .before-time {
        width: 200px;
        font-size: 16px;
      }

      .name {
        width: 200px;
      }

      .voltage {
        font-weight: bold;
        color: #d74b4b;
      }
    }

    .station-item:hover {
      color: rgba(0, 0, 0, 1);
      font-weight: bold;
      background-color: #e5e5e5;
      background-color: rgba(22, 119, 161, 0.05);
    }
  }

  .station-item.title,
  .station-item.title:hover {
    background-color: #e5e5e5;

    .number,
    .name,
    .voltage,
    .before-time {
      font-weight: normal;
      color: #333333;
    }
  }

  .stations-voltage-list::-webkit-scrollbar-track {
    background: rgba(194, 194, 194, 0.23);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    background-clip: padding-box;
  }

  .stations-voltage-list::-webkit-scrollbar-thumb {
    background: #2faadf;
    border-radius: 11px;
  }

  .stations-voltage-list::-webkit-scrollbar {
    width: 13px;
  }
}

@media (max-width: 768px) {
  .stations-voltage-wrapper {
    width: 90%;
    margin: 12px 0 0 0;

    .stations-voltage-list {
      padding-top: 12px;
    }

    .select-wrapper {
      width: 100%;
      max-width: 300px;
    }

    .station-item {
      display: block;
      padding: 12px;
      background-color: #f9f9f9;
      border-radius: 12px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    }

    .column {
      width: 100%;
      margin-bottom: 6px;
    }

    .station-item .number:before {
      display: contents;
      content: "מספר: ";
    }

    .station-item .name:before {
      display: contents;
      content: "שם עמדה: ";
    }

    .station-item .voltage:before {
      display: contents;
      content: "מתח: ";
    }

    .station-item .before-time:before {
      display: contents;
      content: "תאריך: ";
    }
  }

}


@media (max-width: 480px) {
  .stations-voltage-wrapper {
    padding: 10px;
    margin: 0 8px 0 0;

    .select-wrapper {
      display: flex;
      width: 100%;
      padding: 0;
      margin: 4px 0;

      .select-wrapper-inner {
        width: 100%;
      }
    }

    .down-count {
      font-size: 13px;
      height: 18px;
      min-width: 18px;
      padding: 4px;
    }

    .station-item {
      font-size: 14px;
    }

    .station-item .number:before {
      display: contents;
      content: "מספר: ";
    }

    .station-item .name:before {
      display: contents;
      content: "שם עמדה: ";
    }

    .station-item .voltage:before {
      display: contents;
      content: "מתח: ";
    }

    .station-item .before-time:before {
      display: contents;
      content: "תאריך: ";
    }
  }

}