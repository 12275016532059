.StorageSacksHistory {
    padding: 20px;

    h1 {
        font-size: 24px;
        margin-bottom: 20px;
        text-align: center;
    }

    .error {
        color: red;
        font-size: 18px;

    }

    .search-results {
        border-radius: 8px;
        margin-top: 26px;

        .sacks-found {
            .filters {
                display: flex;
                gap: 1.3rem;
                margin-bottom: 10px;
                padding: 12px 0 6px 0;

                .base-select {
                    width: 300px;
                }
            }

            margin-bottom: 20px;

            h2 {
                font-size: 24px;
                color: #333;
                margin-bottom: 10px;
            }

            .result-table {
                max-width: 40vw;
                max-height: 60vh;
                border-collapse: collapse;
                overflow-x: auto;


                th,
                td {
                    border: 1px solid #ddd;
                    padding: 8px;
                    text-align: center;

                }

                th {
                    background-color: antiquewhite;
                }
            }
        }
    }



    .search-sacks {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        border: 1px solid #ccc;
        padding: 12px;
        background-color: #f0f0f0;

        .search-row {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            width: 100%;
        }

        .btn-print-wrapper {
            margin-right: auto;
            display: flex;

            .btn-print {
                font-size: 16px;
                padding: 6px 12px;
            }
        }

        .search-input {
            display: flex;
            align-items: center;
            gap: 8px;

            .input-field {
                padding: 6px 10px;
                font-size: 14px;
                border: 1px solid #ccc;
                border-radius: 4px;
                width: 100%;
                min-width: 150px;
            }
        }



        .datepicker-container {
            display: flex;
            gap: 8px;
            align-items: center;

            .date-picker {
                display: flex;
                align-items: center;
                padding: 6px 8px;
                border-radius: 4px;
                border: 1px solid #ccc;
                width: 6rem;
            }

            .time-picker {
                padding: 6px 12px;
                border-radius: 4px;
                border: 1px solid #ccc;
                width: 4rem;
            }

            .react-datepicker__triangle {
                transform: translateX(0px) !important;
                left: 50% !important;
                margin-left: -8px !important;
            }

        }


        .search-button {
            padding: 5px 18px;
            font-size: 16px;
            background-color: #3498db;
            color: #fff;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
                background-color: #267bb5;
            }
        }


    }

    @media screen and (max-width: 768px) {
        .search-row {
            flex-direction: column;
            align-items: stretch;
        }

        .search-input,
        .date-picker,
        .time-picker {
            width: 100%;
        }

        .btn-print-wrapper {
            margin-right: 0;
            justify-content: center;
        }

        .search-results {
            .sacks-found {
                .filters {
                    .base-select {
                        width: 200px;
                    }
                }

                .result-table {
                    overflow-x: auto;
                    max-width: 96vw;
                    max-height: 100%;
                }
            }
        }
    }
}