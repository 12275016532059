.damaged-packages-page {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    width: 100%;
}

.damaged-packages-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 60vw;
    max-width: 500px;
    text-align: center;

    .manual-barcode-container {
        display: flex;
        align-items: center;
        gap: 10px;
        margin: 10px 0 15px 0;

        input {
            flex: 1;
            padding: 6px;
            font-size: 16px;
            border: 2px solid #ccc;
            border-radius: 5px;
            outline: none;
            transition: border-color 0.3s;
            direction: rtl;

            &:focus {
                border-color: #007bff;
            }
        }

        .manual-barcode-btn {
            padding: 6px 6px;
            margin: 0 8px 0 0;
            font-size: 1rem;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            background-color: #3498db;
            color: white;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: #2980b9;
            }

            &:active {
                background-color: #004099;
            }
        }
    }


    .loading {
        font-size: 1rem;
        font-weight: bold;
        color: #007bff;
        text-align: center;
        animation: fadeIn 1s ease-in-out infinite alternate;
    }

    @keyframes fadeIn {
        from {
            opacity: 0.4;
        }

        to {
            opacity: 1;
        }
    }

    .report-btns-wrapper {
        display: flex;
        gap: 1rem;

        .report-btn {
            padding: 6px 12px;
            margin: 0 0 12px 0;
            font-size: 1rem;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            background-color: #FFA500;
            color: white;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: #FF8C00;
            }
        }
    }


    h2,
    h4 {
        color: #333;
        margin-bottom: 15px;
    }

    #barcode-scanner {
        width: 100%;
        max-width: 400px;
        border: 2px solid #ddd;
        padding: 10px;
        border-radius: 8px;
        background-color: white;
        margin-bottom: 20px;
    }

    .barcode-info {
        font-size: 1rem;
        color: #555;
        margin: 10px 0;
    }

    .camera-feed {
        width: 100%;
        max-width: 400px;
        border-radius: 8px;
        margin-bottom: 20px;
    }

    .captured-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;

        .captured-image {
            max-width: 100%;
            height: auto;
            border-radius: 8px;
        }
    }

    .error {
        font-weight: bold;
        color: red;
        margin: 0 0 8px 0px;
    }

    .buttons {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 400px;
        margin-top: 15px;
        gap: 12px;

        button {
            padding: 10px 20px;
            font-size: 1rem;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            background-color: #3498db;
            color: white;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: #2980b9;
            }
        }
    }

}

@media (max-width: 600px) {
    .damaged-packages-page {
        padding: 0;

        .damaged-packages-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: #f9f9f9;
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            width: 84vw;
            max-width: 500px;
            text-align: center;
        }

        .buttons {
            flex-direction: row;
            align-items: center;

            button {
                width: 8rem;
            }
        }
    }

}