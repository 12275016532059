.tile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 100px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    text-align: center;
    padding: 1rem;
    background-color: #f8f9fa;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
    }

    .tile-title {
        font-size: 16px;
        font-weight: bold;
        color: #333;
    }
}


.tile-red {
    background-color: #ffcccc;
}

.tile-blue {
    background-color: #cce5ff;
}

.tile-green {
    background-color: #d4edda;
}

.tile-link-btn {
    background-color: transparent;
    border: 1px solid #007bff;
    color: #007bff;
    text-align: center;

    &:hover {
        background-color: #007bff;
        color: white;
    }
}

@media (max-width: 600px) {
    .tile {
        width: 78vw;
    }


}