.adt-wrapper {
  margin: 2rem 0 2rem 0;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50vw;

  .block,
  .unblock {
    font-size: 16px;
    padding: 8px 12px;
    background-color: #FF2C2C;
    margin-top: 1rem;
  }

  .error-text {
    color: red;
  }

  .adt-inner-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    width: 100%;
    justify-content: center;
    gap: 10px;

    &.name-row,
    &.phone-email-row {
      .ucs-input-wrapper {
        width: calc(50% - 10px);
      }
    }

    .ucs-input-wrapper {
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      label {
        display: block;
        font-weight: 600;
        margin-bottom: 5px;
        font-size: 16px;
        color: #333;
      }

      .ucs-input {
        max-width: 350px;
        max-height: 22px;
        padding: 10px;
        font-size: 14px;
        border: 1px solid #ccc;
        border-radius: 4px;
        background-color: #fff;
        color: #333;
        transition: border-color 0.3s;
        margin: 0;

        &.ucs-ltr {
          direction: ltr;
        }

        &:focus {
          border-color: #007bff;
          outline: none;
        }

        &[disabled] {
          background-color: #f1f1f1;
        }
      }
    }

    .select-country,
    .select-language {
      width: 375px;
      font-size: 14px;
    }

    .ucs-pdt-flex1 {
      padding: 0 6px;

      label {
        font-size: 16px;
        font-weight: bold;
      }

      .ucs-input {
        margin: 0;
        max-width: 376px;
      }

      .status {
        padding-right: 8px;
      }
    }
  }

  .ucs-input-wrapper input[type='checkbox'] {
    margin-right: 10px;
  }

  .ol-edit-btn {
    margin-left: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    border: none;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #0056b3;
    }
  }

  label {
    display: flex;
    align-items: center;
  }

  .fa-wheelchair {
    margin-right: 8px;
    font-size: 18px;
  }

  .label {
    font-size: 14px;
    color: #333;
  }

  .adt-options {
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 386px;

    label {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #333;
      gap: 10px;

      input[type='checkbox'] {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }

      .fa-wheelchair {
        font-size: 2rem;
        margin-right: 8px;
        color: #44B407;
      }

      .label {
        font-size: 14px;
        color: #333;
      }
    }

    .ol-edit-btn {
      padding: 8px 8px;
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;
      font-size: 14px;
      max-width: 160px;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}