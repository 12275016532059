.station-photos-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;

    .upload-area {
        width: 100%;
        max-width: 400px;
        padding: 20px;
        border: 2px dashed #007bff;
        border-radius: 10px;
        text-align: center;
        cursor: pointer;
        transition: background 0.3s ease;
        margin-bottom: 20px;

        &:hover,
        &.drag-over {
            background: rgba(0, 123, 255, 0.1);
        }
    }

    h1 {
        margin-bottom: 12px;
    }

    .image-upload-input {
        display: none;
    }

    .image-upload-button {
        background-color: #007bff;
        color: white;
        font-size: 16px;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 8px;
        transition: background 0.3s ease;

        &:hover {
            background-color: #0056b3;
        }
    }

    .photos-grid {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: center;
        margin-top: 20px;
    }

    .photo-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 180px;
        padding: 8px 8px 6px 8px;
        background: #f9f9f9;
        border-radius: 8px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .station-photo {
        width: 100%;
        height: 150px;
        object-fit: cover;
        border-radius: 5px;
        cursor: zoom-in;
    }

    .photo-actions {
        display: flex;
        flex-direction: row;
        gap: 10px; // Adds spacing between buttons
        align-items: center;
        padding: 6px 0px 0 0;

        .download-button {
            background: #007bff;
            color: #fff;
            font-size: 14px;
            padding: 6px 12px;
            border: none;
            cursor: pointer;
            border-radius: 5px;
            transition: background 0.3s ease, transform 0.2s ease;

            &:hover {
                background: #0056b3;
                transform: scale(1.05);
            }

        }

        .delete-button {
            width: auto;
            background: #ddd;
            color: #555;
            font-size: 14px;
            padding: 6px 12px;
            border: 1px solid #ccc;
            cursor: pointer;
            border-radius: 5px;
            transition: background 0.3s ease, color 0.3s ease, transform 0.2s ease;

            &:hover {
                background: #ff4d4d;
                color: white;
                transform: scale(1.05);
            }
        }
    }



    .no-photos-message {
        margin-top: 20px;
        font-size: 18px;
        color: #555;
        text-align: center;
    }

    .modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border-radius: 0px;
        background: rgba(0, 0, 0, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
        cursor: pointer;
    }

    .modal-content {
        max-width: 90%;
        max-height: 90%;
        background: white;
        padding: 10px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

    .modal-content img {
        width: 100%;
        height: auto;
        max-height: 90vh;
        object-fit: contain;
    }

}