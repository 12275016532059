.order-editor-wrapper {
  padding: 30px 30px 0 0;
  /* NEW ! RECONSIDER.. : */
  display: flex;
  flex-direction: column;
  flex: 5;
  margin-bottom: 50px;

  /* background: blueviolet; */
  .white-frame-child-wrapper {
    position: relative;
  }

  .order-editor-date-frames-container {
    display: flex;
  }

  .order-editor-frame-wrapper {
    display: flex;
    flex: 1;
    margin: 10px 0;

    justify-content: center;
  }

  .order-editor-details-frame {
    position: relative;
    justify-content: center;
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0 4px;
  }

  .order-editor-details-frame-title {
    position: absolute;
    top: -10px;
    padding: 0 10px;
    right: 0;
  }

  .order-editor-details-frame-value {
    margin: 12px 0 -6px 0;
    background: #29a7df0a;
    text-align: center;
    min-width: 180px;
    border-radius: 4px;
  }

  .order-editor-green-value {
    color: #05b409;
  }

  .order-editor-single-date-wrapper {
    display: flex;
    flex: 1;
    max-width: 210px;
    align-self: center;
    justify-content: center;
    white-space: nowrap;
    margin: 0 15px 0 0;
  }

  .order-editor-buttons-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .order-editor-btn {
    font-size: 18px;
    font-weight: 700;
    padding: 10px 85px;
  }

  .order-editor-btn-red {
    background: #f4f5f7;
    color: #d74b4b;
    border: solid 2px #d74b4b;
  }

  .order-editor-bottom-section {
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding: 20px 0 0 20px;
  }

  .order-editor-bottom-right-part {
    display: flex;
    flex: 1;
    align-items: flex-end;
  }

  .order-editor-bottom-left-part {
    display: flex;
    flex-direction: column;
    flex: 1;
    white-space: nowrap;
  }

  .order-editor-bottom-right-black-btn {
    font-size: 16px;
    font-weight: 600;
    background: black;
    padding: 10px 15px;
    margin: 10px 20px;
  }

  .order-editor-bottom-holder {
    display: flex;
    justify-content: space-between;
  }

  .order-editor-bottom-left-flex-wrapper {
    display: flex;
    flex: 1;
    justify-content: space-around;
    align-items: center;
    padding: 4px 15px;
  }

  .order-editor-bottom-left-btn-wrap {
    justify-content: center;
    align-items: center;
    flex: 1;
    color: black;
    font-size: 16px;
  }

  .order-editor-bottom-left-green-btn {
    font-size: 16px;
    padding: 6px 4px;
    font-weight: 600;
    background: #05b409;
    width: 100%;
  }

  .order-editor-bottom-left-inner-wrapper {
    display: flex;
    flex: 1;
    padding: 0 10px;
    align-items: center;
    justify-content: center;
  }

  .order-editor-discount-editable {
    margin: 0;
    padding: 0;
    outline: none;
    background: none;
    width: 60px;
    font-family: Heebo;
    font-weight: 1000;
    color: #333333;
    font-size: 18px;
    text-align: start;
  }

  .order-editor-status {
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #29a7df;
    font-size: 21px;
    font-weight: 600;
    z-index: 3;
  }

  .order-editor-status-val {
    padding: 5px;
    background: #29a7df;
    margin: 0 20px;
    color: black;
    font-weight: 600;
    min-width: 300px;
  }

  .order-editor-percentagee {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Heebo;
    font-weight: 1000;
    color: #333333;
    font-size: 18px;
  }

  .order-editor-comp-wrapper {
    display: flex;
    flex: 1;
    justify-self: center;
    align-self: flex-start;
    justify-content: center;
    /* background: violet;
    color: red; */
  }

  .order-history-button {
    display: inline-block;
    cursor: pointer;
    padding: 0 4px 0 0;
    color: #29a7df;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    text-decoration: underline;
  }

  .order-data-caption {
    font-size: 17px;
    color: #c1bfbf;
    display: inline-block;
    padding: 0 10px;
  }

  .btn-change-customer-holder {
    text-align: center;
    margin: 6px 0 0 0;
  }

  .order-data-value {
    font-size: 17px;
    color: #29a7df;
    font-weight: bold;
    display: inline-block;
  }

  .current-location,
  .route-name,
  .order-type {
    color: #29a7df;
    font-size: 32px;
    font-weight: bold;
    display: block;
    direction: ltr;

    .azrieli-business-title {
      display: inline-block;
      font-size: 20px;
      padding: 0 7px;
    }
  }

  .is-monthly-payment {
    position: absolute;
    display: inline;
    text-align: center;
    right: -52px;
    top: 5px;
    color: #df2929e3;
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    border: solid 1px #df2929e3;
    background-color: #fdb65b;
    padding: 10px;
    border-radius: 42px;
  }

  .is-return-to-warehouse,
  .is-return-to-source {
    position: absolute;
    display: inline;
    text-align: center;
    right: -52px;
    top: 5px;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    border: solid 1px #df2929e3;
    background-color: #e66465;
    padding: 10px;
    border-radius: 42px;
  }

  .is-return-to-warehouse {
    border: solid 2px #549aa7;
    background-color: rgb(147 198 207);
    color: #000;
    padding: 12px;
    right: -90px;
  }

  .blbtn-wrapper.is-return-to-warehouse,
  .blbtn-wrapper.is-return-to-source {
    position: static;
  }

  .order-data-wrapper {
    padding: 0 20px;
    display: block;
  }

  .change-user-btn {
    font-size: 16px;
    padding: 2px 12px;
  }

  .suppliers-List {
    margin: 10px 10px 8px 0;
    display: flex;
    align-items: center;
    z-index: 4;
  }

  .select-holder {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .supplier-label {
      color: black;
      font-family: Heebo;
      font-size: 16px;
      margin-left: 14px;
    }

    .supplier-selector {
      width: 300px;
      z-index: 9;
    }

    .station-List {
      margin: 10px 10px 8px 0;
      display: flex;
      align-items: center;
      z-index: 4;
      margin-right: 40px;
    }

    .station-label {
      margin-left: 14px;
    }
  }

  .optional-wrong-door {
    padding: 8px 14px;
    border-radius: 6px;
  }

  .is-need-accessibility {
    background: #ffe8e8;
    margin-right: 30px;
    color: #05b409;
    padding: 6px 12px 6px 16px;

    border-radius: 6px;
  }

  .fa-exclamation-triangle {
    font-size: 22px;
    color: #d74b4b;
  }

  .is-need-accessibility .label,
  .optional-wrong-door .label {
    display: inline-block;
    font-weight: bold;
    font-size: 16px;
    margin: 0 10px 0 0;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
  }

  .btn-tracking {
    font-size: 20px;
    padding: 0 6px;
  }
}

.order-editor-spinner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 80vw;
}

.fa-wheelchair {
  font-size: 26px;
  color: #05b409;
  padding: 0 0 0 5px;
}

.warning-text {
  color: red;
}

.close-order-potential {
  font-size: 18px;
  color: red;
  background-color: white;
  padding: 4px 12px;
  border: 1px solid #E4E4E4;
  width: 206px;
}

.station-change-state-ind {
  color: #05b409;
}

.station-change-state-ind-arrow {
  border: solid #05b409;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.station-change-billing {
  margin-right: 8px;
}