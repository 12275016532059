.cus-det-frame {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 165px;
  justify-content: space-around;
  /* padding-left: 120px; */
  white-space: nowrap;
}

.cus-det-frame-faded-title {
  font-size: 17px;
  color: #c1bfbf;
  font-weight: 500;
}

.cus-det-frame-user-name-wrapper {
  display: flex;
  align-items: flex-end;
  margin: 10px 0;
}

.cus-det-frame-user-name {
  color: #29a7df;
  font-size: 35px;
  font-weight: 1000;
  line-height: 30px;
  margin: 0 0 0 5px;
  cursor: pointer;
}

.cus-det-frame-debt-wrapper {
  display: flex;
  justify-content: center;
  background: #ffe8e8;

  padding: 10px 0px;
  font-size: 18px;
  max-width: 250px;
}

.cus-det-frame-new-debt-wrapper {
  display: flex;
  justify-content: center;
  padding: 2px 0px;
  font-size: 18px;
  max-width: 250px;
}

.cus-det-frame-debt-amount {
  color: #d74b4b;
  font-weight: 700;
  margin: 0 5px;
}

.cus-det-frame-part2 {
  display: flex;
  flex-direction: column;
}

.cus-det-frame-details-wrapper {
  /* background: red; */
  margin: 30px 0px;
}

.cus-det-frame-details {
  display: flex;

  .cus-det-frame-details-value {
    margin: 0 10px 0 0;
  }

  .user-is-monthly-payment {
    color: #df2929e3;
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    border: solid 1px #df2929e3;
    background-color: #fdb65b;
    padding: 2px 10px;
    border-radius: 20px;
  }
}